import React from 'react';
import './styles.css';
const WelcomeCard=()=>{
    return(
        <div className="welcome_continer">
            <div className="welcome_header">
                <p className="welcome_header">Welcome to Nova!</p>
                <p className="welcome_body">Your recent books, Articles, and Journals will appear here.to get started, brows recommended books below or shop the store</p>
            </div>
        </div>
    )
}

export default WelcomeCard;