import React, {useEffect,useState } from 'react';
import ListCard from '../../component/dashboard/list_Card';
import SubjectCard from '../../component/dashboard/subject_Card';
import DownCard from '../../component/dashboard/downloaded_Card';
import Space from '../../component/dashboard/space';
import { useSelector,useDispatch } from 'react-redux';
import{useHistory}from 'react-router-dom'
import {home} from '../../api/api'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {setSubject,setNewBooks,setBestRatingBooks,setOpenAccessBooks,setNewJournals,setBestRatingJournals} from '../../redux/actions/dashboardActions';
const Dashboard = () => {
  const history=useHistory();
  const [paidBook,setPaidBook]=useState([])
  const [isLoading,setIsLoading]=useState(false)
  const subject=useSelector((state)=>state.App.subject);
  const newbooks=useSelector((state)=>state.App.newbooks);
  const bestratingbooks=useSelector((state)=>state.App.bestratingbooks);
  const openaccessbooks=useSelector((state)=>state.App.openaccessbooks);
  const newjournals=useSelector((state)=>state.App.newjournals);
  const bestratingjournals=useSelector((state)=>state.App.bestratingjournals);
  const dispatch=useDispatch();
 
  useEffect(() => {
    window.scrollTo(0, 0)
    const leaves_userCredentials = JSON.parse(localStorage.getItem('leaves_userCredentials'));
    if (leaves_userCredentials != null) {
        if (leaves_userCredentials.emailVerified != 1) {
            history.push('./login');
        } else {
           fetchDashboardData()            
        }
    }
    else {
        history.push('./login');
    }
   
  }, [])
 
  const fetchDashboardData = async () => {
    setIsLoading(true)
    const leaves_userCredentials = JSON.parse(localStorage.getItem('leaves_userCredentials'));
    let axios = require('axios');
    let config = {
      method: 'get',
      url: home,
      headers: {
        'apikey': leaves_userCredentials.apikey     
       }
    };
     axios(config)
    .then((response)=>{
      dispatch(setSubject(response.data.subjectList));
      dispatch(setNewBooks(response.data.recentbook));
      dispatch(setBestRatingBooks(response.data.bestratingbooks));
      dispatch(setOpenAccessBooks(response.data.openaccessbooks));
      dispatch(setNewJournals(response.data.recentJournals));
      dispatch(setBestRatingJournals(response.data.bestRatingJournals));
      setPaidBook(response.data.bestpaidbooks);
      setIsLoading(false);
    })
    .catch(function (error) { console.log(error); });
   }
  const goToList = (titel) => {
    history.push('/list')
  }
  const goToSubjectList = (titel) => {
  }
  return (
    <div className="dashboard">
        {/* <Carousel className='Carousel' showArrows={true} showThumbs={false} autoPlay={true} onChange={()=>{}} onClickItem={()=>{}} >
                <div>
                    <img src={Img} />
                </div>
                
            </Carousel> */}
      {/* <DownCard/> */}
      <SubjectCard data={subject} gotosubject={goToSubjectList} isLoading={isLoading}  />
      <Space hig="10px" color="#fff"/>
      <Space hig="1px" color="#ECF0EF"/>
      <ListCard cardtitel="New Releases" data={newbooks} titel="newreleases" isBook={true} access={"paid"} isLoading={isLoading} />
      <Space hig="1px" color="#ECF0EF"/>
      {/* <ListCard titel="Best Rated Journals" data={bestratingjournals} isBook={false} isLoading={isLoading} />
      <Space hig="1px" color="#ECF0EF"/> */}
      <ListCard cardtitel="Books By Rating" titel="booksbyrating" data={bestratingbooks} isBook={true} access={"paid"} isLoading={isLoading} gotolist={() => { goToList("BEST RATING BOOKS") }} />
      <Space hig="1px" color="#ECF0EF"/>
      {/* <ListCard titel="Recent Journals" data={newjournals} isBook={false} isLoading={isLoading} />
      <Space hig="1px" color="#ECF0EF"/> */}
      <ListCard cardtitel="Paid Book" titel="paidbook" data={paidBook} isBook={true} access={"paid"} isLoading={isLoading} gotolist={() => { goToList("Paid Book") }}  />
      <Space hig="1px" color="#ECF0EF"/>
      <ListCard cardtitel="Open Access Books" titel="openaccessbooks"  data={openaccessbooks} isBook={true} isLoading={isLoading} access={"free"} gotolist={() => { goToList("OPEN ACCESS BOOKS") }} />
      <Space hig="1px" color="#ECF0EF"/>
      <Space hig="10px" color="#fff"/>
     </div>
  );
}

export default Dashboard;
