import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { Spin, Image, Button,message,Popover,Typography } from 'antd';
import { DeleteOutlined, ReadOutlined,FilePdfOutlined,FilterOutlined } from '@ant-design/icons';
import { setSelectedItem } from '../../redux/actions/dashboardActions';
import { useIndexedDB } from 'react-indexed-db';
import Space from '../../component/dashboard/space';
import Emptycard from '../../component/Empty';
import './styles.css';
import Skeletonbook from '../../component/skeleton/skeletonbook';
import Bookcover from '../../api/images/book.png'

const { Title } = Typography;

const Downloaded = (props) => {
    const { getAll, deleteRecord } = useIndexedDB('epub');
    const history = useHistory();
    const dispatch = useDispatch();
    const [downloaded, setdownloaded] = useState([])
    const [loading, setloading] = useState(true)
    const [pdf, setpdf] = useState([])
    const [epub, setepub] = useState([])


    useEffect(() => {
        window.scrollTo(0, 0)
        getAll().then(files => {
            let pdf = files.filter(e => e.type === "pdf");
            let epub = files.filter(e => e.type === "epub");
            setdownloaded(files)
            console.log(files)
            setpdf(pdf)
            setepub(epub)
            setloading(false)
        });
    }, [])


    const deletePost = async (id) => {
        deleteRecord(id).then(event => {
            message.success('Book Deleted from your downloaded list');

        });
        getAll().then(files => {
            let pdf = files.filter(e => e.type === "pdf");
            let epub = files.filter(e => e.type === "epub");
            setdownloaded(files)
            setpdf(pdf)
            setepub(epub)
            setloading(false)
        });
    }
    const deletemultipulPost = async (epubid,pdfid) => {
        deleteRecord(epubid).then(event => {

        });
        deleteRecord(pdfid).then(event => {
            message.success('Book Deleted from your downloaded list');

        });
        getAll().then(files => {
            let pdf = files.filter(e => e.type === "pdf");
            let epub = files.filter(e => e.type === "epub");
            setdownloaded(files)
            setpdf(pdf)
            setepub(epub)
            setloading(false)
        });
    }
    const add3Dots = (string, limit) => {
        var dots = "...";
        if (string.length > limit) {
            // you can also use substr instead of substring
            string = string.substring(0, limit) + dots;
        }

        return string;
    }
   

    const goTopdf = (item) => {
        history.push({pathname:'/read/book/pdf/'+"0000",state:{contentid:"0000",fuzzyid:"0000" ,isbn:"0000",name:item.name,type:"book",isonline:'no'}})

    }

    const goToepub = (item) => {
        history.push({pathname:'/read/book/epub/'+"offline",state:{contentid:"0000",isbn:"0000",name:item.name,type:"book"}})

        // history.push('/reader/'+"0000"+"/"+"0000"+"/"+item.name+"/"+"000"+"/"+"no"+"/"+"book")

    }
    const readEpub = (item) => {
        if (item.type == "epub") {
            return (
                <div>
                    <Button onClick={() => { goToepub(item) }} type="primary" icon={<ReadOutlined />} size="small">Continue Reading</Button>
                    <DeleteOutlined onClick={() => { deletePost(item.id) }} className="deleteicon"/>
               </div>
            )
        } else {
            return (
                <div>
                    <Button onClick={() => { goTopdf(item) }}  type="primary" icon={<FilePdfOutlined />} size="small">Continue Reading</Button>
                    <DeleteOutlined onClick={() => { deletePost(item.id) }} className="deleteicon"/>
               </div>
            )
        }
    }
    const content =(item,ishavepdf)=> (
        <div>
          <p style={{fontFamily: 'var(--font)',letterSpacing:'1px'}} onClick={()=>{goToepub(item)}}> <ReadOutlined style={{fontSize:'15px',marginTop:'10px',color:'blue',marginRight:'10px'}} />Epub</p>
          <p style={{fontFamily: 'var(--font)',letterSpacing:'1px'}} onClick={()=>{goTopdf(ishavepdf)}}> <FilePdfOutlined style={{fontSize:'15px',marginTop:'10px',color:'red',marginRight:'10px'}} />Pdf</p>
        </div>
      );

      if(loading){
          return(<Skeletonbook/>)
      }

    if (downloaded.length == 0) {
        return (
            <div style={{ display:"flex", height: '90vh', width: '100%', alignItems: "center", justifyContent: 'center' }}>
                    <Emptycard  descriptin="You don't have any downloaded Book " link={()=>{props.setsub()}}/>
                
            </div>
        )
    }
    return (

        <div className="bookcard">
            {epub.map((item) => {
                let ishavepdf = pdf.find(e => e.name.replace("-pdf","") == item.name );
                 if(ishavepdf ){
                   return (
            <div  className="bookcard_continer" >
                <div className="bookcover_continer" >
                    <Image  className="bookcover"
                            fallback={Bookcover}
                            src={item.img} />
                </div>
                <div className="bookcontent">
                    <div >
                    <div className="booktitle" dangerouslySetInnerHTML={{ __html:add3Dots(item.name.replace("-pdf", ""),60) }}/>
                </div>
                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px',marginBottom:'10px' }}>
                    <div>
                    <Button onClick={() => { goToepub(item) }} type="primary" icon={<ReadOutlined />} size="small">Continue Reading</Button>
                    <Popover content={content(item,ishavepdf)}   title={<Title style={{fontFamily: 'var(--font)',letterSpacing:'1px',fontWeight:'bold',fontSize:'15px'}}>Select Reader</Title>} trigger="click"> <FilterOutlined style={{ marginLeft: '10px', fontSize: '20px', marginTop: '10px', color: '#33AFFF' }}/></Popover>
                    <DeleteOutlined onClick={() => { deletemultipulPost(item.id,ishavepdf.id) }} className="deleteicon"/>
               </div>
                    </div>
                </div>
            </div>
                   )
               }else{
                return (
                    <div  className="bookcard_continer" >
                    <div className="bookcover_continer" >
                        <Image  className="bookcover"
                            fallback={Bookcover}
                            src={item.img} />
                    </div>
                    <div className="bookcontent">
                        <div>
                        <div className="booktitle" dangerouslySetInnerHTML={{ __html:add3Dots(item.name.replace("-pdf", ""),60) }}/>
                       
                        </div>
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px' ,marginBottom:'10px'}}>
                           {readEpub(item)}
                        </div>
                    </div>
                </div>
                   )}
            })}
             {pdf.map((item) => {
                let ishavepub = epub.find(e => e.name == item.name.replace("-pdf","") );
                if(ishavepub){
                    return null
                }
                return (
                    <div  className="bookcard_continer" >
                    <div className="bookcover_continer" >
                        <Image  className="bookcover"
                            fallback={Bookcover}
                            src={item.img} />
                    </div>
                    <div className="bookcontent">
                        <div>
                        <div className="booktitle" dangerouslySetInnerHTML={{ __html:add3Dots(item.name.replace("-pdf", ""),60) }}/>
                        
                        </div>
                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', marginTop: '10px',marginBottom:'10px' }}>
                           {readEpub(item)}
                        </div>
                    </div>
                </div>
                   )
            })}
            <Space hig="10px" color="#ffffff" />
        </div>


    )
}

export default Downloaded;