import React, { useState } from "react";
import { Row, Col ,message,Breadcrumb} from 'antd';
import { bookcover, getbook,search } from '../../api/api'
import { useHistory } from 'react-router-dom'
import { LeftOutlined, ArrowLeftOutlined,LoadingOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { updateSeachbooks } from '../../redux/actions/dashboardActions';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../component/dashboard/header'
import './styles.css';

const Searchscreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isloading, setisloading] = useState(false)
    const [ismoreloading, setismoreloading] = useState(false)
    const [ismoredata, setismoredata] = useState(true)
    const searchbooksvalues = useSelector((state) => state.App.searchbooksvalues);
    const [text, settext] = useState(searchbooksvalues.searchtext)

    const getBooksList = async () => {
        message.destroy()
        let url = search;
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(url+"cnt=1&q="+searchbooksvalues.searchtext+"&sort=recentold", requestOptions,)
            .then(response => response.json())
            .then(result => {
                if(result.count==0){
                    setismoredata(false)
                    message.warning('No Data Found ');

                }else{
                    dispatch(updateSeachbooks(searchbooksvalues.books.concat(result.data), searchbooksvalues.pagination + 20, text))

                }
                setisloading(false)
                setismoreloading(false)

            })
            .catch(error => {
                setisloading(false)
                setismoreloading(false)
            });

    }

    const onSearch = () => {
        message.destroy()
        console.log(text.length)
        if (text.length==0) {
            message.warning('Search box is Empty');

        }else{
            dispatch(updateSeachbooks([], 0, text))
            setismoredata(true)
            setisloading(true)
            getBooksList()
        }
    }

    const onClear = () => {
        settext("")
        dispatch(updateSeachbooks([], 0, ""))

    }

    const loademore = () => {
        setismoreloading(true)
        getBooksList()
    }

    const updatetext = event => {
        settext(event.target.value)
        
        
        

    }
    const handleAnswerChange=(event)=>{
                message.destroy()

        console.log(event.key)
        if (event.key=="Enter") {
            if (text.length==0) {
                message.warning('Search box is Empty');
    
            }else{
                dispatch(updateSeachbooks([], 0, text))
                setismoredata(true)
                setisloading(true)
                getBooksList()
            }

        } 
		
	
}



    return (
        <div className="searchscreen" >
                                   <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <Header/>
               <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">Search</Breadcrumb.Item>
             </Breadcrumb>
            <div className="searchheader">
               
                <div className="searchinput">
                    <div className='searchbox'>
                        <input autoFocus={true}  type="search" onKeyPress={handleAnswerChange}  className="searchboxfiled" placeholder="Search by Title,Author,Book ..." name="search" value={text} onChange={updatetext} />
                      {text &&  <CloseOutlined onClick={onClear} className='clearicon' />}
                        {isloading ? <div className='searchicon'> <LoadingOutlined  /></div> : <SearchOutlined onClick={onSearch} className='searchicon' />}
                    </div>
                </div>
            </div>
            {searchbooksvalues.books.length > 0 ? <Row className="booklist">
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 1 }}>
                    <Row>
                        {searchbooksvalues.books.map(item => (
                            <Col className="journalList" xs={12} sm={8} md={6} lg={4} xl={4}>
                                <div onClick={() => { history.push({ pathname: '/book/isbn/' + item.isbn, state: { contentid: item.contentId } }) }} className="journalCover" style={{ backgroundImage: `url(${bookcover + item.fuzzyId +"/"+ item.cover})`, backgroundColor: "#EEFBF9" }}>
                                </div>
                            </Col>
                        ))}
                        {/* {ismoredata ? <div className="moreloading">
                            {ismoreloading ? <div className="moreloading"> <LoadingOutlined /> <span>Loading...</span></div> : <div className="moreloading" onClick={loademore}> <span>Load More</span></div>}
                        </div> : null} */}
                    </Row>

                </Col>
            </Row> :
                <div className="nodata">
                    Search by Title,Author,ISBN,Series,Collection,Publisher or subject
                </div>}
                </Col>
        </div>
    );
}


export default Searchscreen;