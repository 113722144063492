import React from 'react';
import 'antd/dist/antd.css';
import { Empty, Button } from 'antd';
import EmptyImg from '../api/images/empty.svg'
const Emptycard = (props) => {
    return (
        <Empty
            image={EmptyImg}
            imageStyle={{
                height: 60,
            }}
            description={
                <span style={{fontFamily:'var(--font)',letterSpacing:'1px'}}>
                   {props.descriptin}
                </span>
            }
        >
            <Button onClick={props.link} type="primary">{props.btntext}</Button>
        </Empty>
    )
}

export default Emptycard;