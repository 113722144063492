import React, { useState, useEffect } from "react";
import { Col, Row, Button, message, Popover, Modal, Input, Breadcrumb, Tabs, Typography, Result } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../component/dashboard/header'
import { useHistory, useLocation } from 'react-router-dom'
import { bookcover, bookpath, saveandremovefav, bookdetails, favoriteURL, subscriptionURL, passcodevalidation, serverurl } from '../../api/api';
import { RWebShare } from "react-web-share";
import { ReadOutlined, ArrowLeftOutlined, FilePdfOutlined, FilterOutlined, LikeFilled, HeartFilled, LoadingOutlined, LeftOutlined, HeartOutlined, ShareAltOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import './styles.css';
const { TabPane } = Tabs;
const { Title } = Typography;

const NewDescription = (props) => {
    const location = useLocation();
    const contentid = location.state.contentid
    const isbn = props.match.params.isbn
    const history = useHistory();
    const dispatch = useDispatch();
    const leaves_userCredentials = JSON.parse(localStorage.getItem('leaves_userCredentials'));
    const [description, setdescription] = useState('');
    const [loadings, setloadings] = useState(false);
    const [loading, setloading] = useState(false);
    const [subloading, setsubloading] = useState(false);
    const [likeiconcolor, setlikeiconcolor] = useState('#E8E4E4')
    const [isfavorite, setisfavorite] = useState(false)
    const [book, setbook] = useState({})
    const [isfree, setisfree] = useState(77)
    const [bookaccess, setbookaccess] = useState("77")
    const [issub, setissub] = useState(false)
    const [visible, setVisible] = useState(false);
    const [readervisible, setreadervisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState('Content of the modal');
    const [password, setpassword] = useState("")
    const [ispasswordvalid, setispasswordvalid] = useState(false)
    const [isbtnloading, setsbtnloading] = useState(false)
    const [isuserclickbuy, setuserclickbuy] = useState(false)
    const [authors, setauthors] = useState([])
    const [toc, settoc] = useState()
    const [error, seterror] = useState(false)
    const [format, setformat] = useState('')
    const [pdf, setpdf] = useState(false)
    const [epub, setepub] = useState(false)

    useEffect(async () => {
        window.scrollTo(0, 0)
        await Promise.all([
            getBookdeltais(),
            getSub(leaves_userCredentials.apikey),
            getFavorite(leaves_userCredentials.apikey),

        ])

    }, [])

    const refreash = async () => {
        setbook({})
        setdescription('')
        setbookaccess("77")
        setisfavorite(false)
        setissub(false)
        await Promise.all([
            getBookdeltais(),
            getFavorite(leaves_userCredentials.apikey),
            getSub(leaves_userCredentials.apikey),
        ])
    }

    const getBookdeltais = () => {
        setloading(true)
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(bookdetails + contentid, requestOptions)
            .then(response => response.json())
            .then(result => {
                let getauthors = result.authors.map(item => item.name)
                setbook(result.data)
                setdescription(result.data.description)
                setbookaccess(result.data.access)
                setpdf(result.data.pdfExists)
                setepub(result.data.epubExists)

                setauthors(getauthors)
                settoc(result.data.toc)
                setloading(false)

            })
            .catch(error => {
                console.log(error)

                seterror(true)
                setloading(false)

            });
    }
    const getFavorite = async (apikey) => {
        let axios = require('axios');
        let config = {
            method: 'get',
            url: favoriteURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {

                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj => obj.eisbn === isbn);
                    if (isValuefound.length > 0) {
                        setisfavorite(true)
                    } else {
                        setisfavorite(false)
                    }


                } else {
                    setisfavorite(false)

                }

            })
            .catch(function (error) {

            });
    }

    const goTopdf = () => {
        history.push({pathname:'/read/book/pdf/'+book.elasticId,state:{contentid:contentid,fuzzyid:book.fuzzyId ,isbn:isbn,name:book.name,type:"book",isonline:'yes',cover:book.cover}})
    }

    const goToepub = () => {
        history.push({ pathname: '/read/book/epub/' + book.elasticId, state: { contentid: contentid, isbn: isbn, name: book.name, type: "book",fuzzyid:book.fuzzyId,cover:book.cover} })
    }

    const addOrRemoveLibrary = (id) => {
        setloadings(true);
        var myHeaders = new Headers();
        myHeaders.append("apikey", leaves_userCredentials.apikey);
        var formdata = new FormData();
        formdata.append("contentId", contentid);
        formdata.append("library", id);
        formdata.append("path", bookpath + isbn);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(saveandremovefav, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (id == 1) {
                    message.success('Book added to your favorite list');
                    setisfavorite(true)
                    setloadings(false);
                } else {
                    message.success('Book Removed from your favorite list');
                    setisfavorite(false)
                    setloadings(false);

                }
            })
            .catch(error => {
                setisfavorite(false)
                setloadings(false);
                message.error('Sorry, something went wrong.');


            });
    }

    const content = (item) => (
        <div>
            <p onClick={() => { goToepub(item) }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px' }} />Epub</p>
            <p onClick={() => { goTopdf(item) }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px' }} />Pdf</p>
        </div>
    );
    const getSub = async (apikey) => {
        setsubloading(true)
        let axios = require('axios');
        let config = {
            method: 'get',
            url: subscriptionURL,
            headers: {
                'apikey': apikey
            }
        };
        axios(config)
            .then((response) => {
                if (response.data.data.length > 0) {
                    let isValuefound = response.data.data.filter(obj => obj.eisbn === isbn);
                    if (isValuefound.length > 0) {
                        console.log(isValuefound)
                        setformat(isValuefound[0].format)
                        setsubloading(false)
                        setissub(true)
                    } else {
                        setsubloading(false)
                        setissub(false)
                    }

                } else {
                    setsubloading(false)
                    setissub(false)

                }

            })
            .catch(function (error) {
                console.log(error)
                setsubloading(false)
                setissub(false)
            });
    }

    const showModal = () => {
        setVisible(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        if (password.length > 0) {
            setConfirmLoading(true);
            var myHeaders = new Headers();
            myHeaders.append("apikey", leaves_userCredentials.apikey);
            var formdata = new FormData();
            formdata.append("contentId", contentid);
            formdata.append("passcode", password);
            formdata.append("deviceName", "chrome");
            formdata.append("deviceId", "chromeapp");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(passcodevalidation, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.r == 1) {
                        setConfirmLoading(false);
                        setispasswordvalid(true)
                        setVisible(false)
                        if (book.epubExists) {
                            history.push({ pathname: '/read/book/epub/' + book.elasticId, state: { contentid: contentid, isbn: isbn, name: book.name, type: "book" } })
                        } else {
                            history.push('/pdf/' + contentid + "/" + isbn + "/" + book.name + "/" + book.elasticId + "/" + "yes" + "/" + "book")
                        }
                    } else {
                        setConfirmLoading(false);

                        message.warning('Enter valid password');
                    }
                })
                .catch(error => {
                    message.warning('Enter valid password');
                    setConfirmLoading(false);
                });


        } else {
            setConfirmLoading(false);
            message.warning('Enter valid password');
        }


    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };
    const handleCancelreader = () => {
        console.log('Clicked cancel button');
        setreadervisible(false);
    };
    const onChange = e => {
        setpassword(e.target.value)
        console.log('Change:', e.target.value);
    };
    const contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    
    console.log(targetLink.href);
    }
    const goToreader = () => {
        console.log(format)
        if (bookaccess == 2 && !issub) {
            showModal()
        } else if (issub || bookaccess == 1) {
            if (book.epubExists && book.pdfExists) {
                setreadervisible(true)
            } else if (book.epubExists) {
                goToepub()

            } else if(book.pdfExists) {
                goTopdf()

            }
        }
    }
    if(loading || subloading){
        return(
            <div className="continer">
                                       <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <Header />
            <Breadcrumb separator=" " style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }} >
                <Breadcrumb.Item><ArrowLeftOutlined onClick={() => { history.goBack() }} /></Breadcrumb.Item>
                <Breadcrumb.Item className="pageheadertitle">Book</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '5px', borderRadius: "5px" }}>
                <div style={{ width: '100%' }}>
                    <div style={{ width: '100%',  flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <img className="dcover" src={bookcover + contentid + "/cover.png"} />
                    </div>
                    <div style={{ display: 'flex', marginBottom: '50px', marginTop: '5px', marginLeft: '10px', marginRight: '10px', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center', fontSize: '15px', letterSpacing: '1px', fontFamily: 'var(--font)', color: '#000', fontWeight: 'bold' }}>{book.name}</div>
                        <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Author(s):</span>{authors.toString().replaceAll(",", ", ")}</div>
                        <div>{book.publisherName}</div>
                        <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>ISBN:</span>{book.eisbn}</div>
                        <div style={{ width: '50%', height: '40px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '20px' }}> <HeartFilled style={{ color:"#273746" }}  />
                           
                                <ShareAltOutlined /></div>

                        <div  style={{ width: '50%', height: '40px', backgroundColor: '#ff7600', borderRadius: '5px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', color: '#fff', justifyContent: 'center', fontWeight: 'bold', letterSpacing: '1px' }}><LoadingOutlined/></div>
                    </div>
                    <div className="card-container">
                        <Tabs type="card">
                            <TabPane tab="About Book" key="1">
                                <p style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} dangerouslySetInnerHTML={{ __html: description }} />

                            </TabPane>
                            {!toc ? null : <TabPane tab="Table of Contents" key="2">
                                <div id="toc" style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: toc }} />
                            </TabPane>}
                        </Tabs>
                    </div>
                </div>
              
                <Modal
                    title="Enter Passcode"
                    visible={visible}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}>
                    <Input.Password placeholder="Passcode" value={password} onChange={onChange} />
                </Modal>
            </Row>
            </Col>
        </div>
        )
    }

    if (error) {
        return (
            <div className="continer" >
                <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

                    <Header />
                    <Breadcrumb separator=" " style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }} >
                        <Breadcrumb.Item><ArrowLeftOutlined onClick={() => { history.goBack() }} /></Breadcrumb.Item>
                        <Breadcrumb.Item className="pageheadertitle">Book</Breadcrumb.Item>
                    </Breadcrumb>
                    <Result
                        status="500"
                        title="500"
                        subTitle="Sorry, something went wrong."
                        extra={<Button onClick={() => { history.goBack() }} type="primary">Back</Button>}
                    />
                </Col>
            </div>
        )
    }


    return (

        <div className="continer">
                                   <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>

            <Header />
            <Breadcrumb separator=" " style={{ marginTop: '10px', marginLeft: '10px', marginBottom: '5px' }} >
                <Breadcrumb.Item><ArrowLeftOutlined onClick={() => { history.goBack() }} /></Breadcrumb.Item>
                <Breadcrumb.Item className="pageheadertitle">Book</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="listcontiner" style={{ "padding": "0px", "margin": "0px 0px 0px 0px", padding: '5px', borderRadius: "5px" }}>
                <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <img className="dcover" src={bookcover + book.fuzzyId +"/"+ book.cover} />
                    </div>
                    <div style={{ display: 'flex', marginBottom: '50px', marginTop: '5px', marginLeft: '10px', marginRight: '10px', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center', fontSize: '15px', letterSpacing: '1px', fontFamily: 'var(--font)', color: '#000', fontWeight: 'bold' }}>{book.name}</div>
                        <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>Author(s):</span>{authors.toString().replaceAll(",", ", ")}</div>
                        <div>{book.publisherName}</div>
                        <div style={{ textAlign: 'center', fontSize: '12px', fontFamily: 'var(--font)', color: '#273746' }}><span style={{ fontFamily: 'var(--font)', color: '#ff7600', fontSize: '13px' }}>ISBN:</span>{book.eisbn}</div>
                        <div style={{ width: '50%', height: '40px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', fontSize: '20px' }}>{loadings ? <LoadingOutlined style={{ color: 'red' }} /> : <HeartFilled style={{ color: isfavorite ? "red" : "#273746" }} onClick={() => { if (isfavorite) { addOrRemoveLibrary(0) } else { addOrRemoveLibrary(1) } }} />}
                            <RWebShare
                                data={{
                                    text: book.name,
                                    url: serverurl+"book/isbn/"+book.eisbn,
                                    title: "Share",
                                }}
                                onClick={() => console.log("shared successfully!")}>
                                <ShareAltOutlined /></RWebShare></div>

                        {bookaccess == 0 && !issub || bookaccess == 3 && !issub || bookaccess == 4 && !issub ? <div onClick={() => { window.open(serverurl + "book/isbn/" + book.eisbn); setuserclickbuy(true) }} style={{ width: '50%', height: '40px', backgroundColor: '#ff7600', borderRadius: '5px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', color: '#fff', justifyContent: 'center', fontWeight: 'bold', letterSpacing: '1px' }}>Buy Now</div> : !pdf && !epub ? null:<div onClick={goToreader} style={{ width: '50%', height: '40px', backgroundColor: '#ff7600', borderRadius: '5px', marginTop: '10px', fontFamily: 'var(--font)', display: 'flex', alignItems: 'center', color: '#fff', justifyContent: 'center', fontWeight: 'bold', letterSpacing: '1px' }}>Start Reading"</div>}
                    </div>
                    <div className="card-container">
                        <Tabs type="card">
                            <TabPane tab="About Book" key="1">
                                <p style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} dangerouslySetInnerHTML={{ __html: description }} />

                            </TabPane>
                            {!toc ? null : <TabPane tab="Table of Contents" key="2">
                                <div style={{ padding: '10px', borderRadius: '5px', fontFamily: 'var(--font)', color: '#273746' }} onClick={contentClickHandler} dangerouslySetInnerHTML={{ __html: toc }} />
                            </TabPane>}
                        </Tabs>
                    </div>
                </div>
                <Modal
                    title={<Title style={{fontFamily: 'var(--font)',letterSpacing:'1px',fontWeight:'bold',fontSize:'15px'}}>Select Reader</Title>}
                    visible={readervisible}
                    footer={null}
                    onCancel={handleCancelreader}>
                    <div>
                        <p style={{fontFamily: 'var(--font)',letterSpacing:'1px'}} onClick={() => { goToepub() }}> <ReadOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'blue', marginRight: '10px',fontFamily: 'var(--font)',letterSpacing:'1px' }} />Epub</p>
                        <p style={{fontFamily: 'var(--font)',letterSpacing:'1px'}} onClick={() => { goTopdf() }}> <FilePdfOutlined style={{ fontSize: '15px', marginTop: '10px', color: 'red', marginRight: '10px',fontFamily: 'var(--font)',letterSpacing:'1px' }} />Pdf</p>
                    </div>
                </Modal>
                <Modal
                    title={<Title style={{fontFamily: 'var(--font)',letterSpacing:'1px',fontWeight:'bold',fontSize:'15px'}}>Enter Passcode</Title>}
                    visible={visible}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}>
                    <Input.Password placeholder="Passcode" value={password} onChange={onChange} />
                </Modal>
            </Row>
            </Col>
        </div>
    );

}

export default NewDescription;