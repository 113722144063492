import React, { useState } from "react";
import './styles.css';
import Constants from '../../asset/Constants';
import Header from '../../component/dashboard/header'
import UserImg from '../../asset/images/user.png';
import { Form, Input, Button, message,Modal,Breadcrumb } from 'antd';
import Space from '../../component/dashboard/space';
import { resetpassword } from '../../api/api'
import {ArrowLeftOutlined} from '@ant-design/icons';
import { useHistory } from 'react-router-dom'

const AccountSetting = () => {
    const history = useHistory();
    const [loadings, setloadings] = useState(false);
    const [btnText, setbtnText] = useState("Change");
    const leaves_userCredentials = JSON.parse(localStorage.getItem('leaves_userCredentials'));
    
    const onFinish = async (values) => {
        console.log(leaves_userCredentials)
        console.log(values)
        if(values.Password === values.cpassword){
        setloadings(true);
        setbtnText("Loading")
        var myHeaders = new Headers();
        myHeaders.append("apikey", leaves_userCredentials.apikey);
        var formdata = new FormData();
        formdata.append("email", leaves_userCredentials.email);
        formdata.append("password", values.Password);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(resetpassword, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.r === 1){
                    setloadings(false);
                    setbtnText("Change")
                    Modal.success({
                       title: 'Success',
                       content: 'Your Password has been Changed.',
                       okText: 'OK',
                     });
                   }else{
                    setloadings(false);
                    setbtnText("Change")
                       message.error("something went wrong please try again")
           
                   }
            })
            .catch(error => {
                setloadings(false);
                setbtnText("Change")
                message.error("something went wrong please try again")
            });
        }else{
            message.error("The password and confirmation password do not match.")

        }
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        // <div>
        //     <Row style={{ "padding": "0px", "margin": "0px 0px 0px 0px" }}>
        //         <Col xs={{ span: 24, offset: 0 }} lg={{ span: 20, offset: 2 }}>
        <div cclassName="continer">
            <Header titel="Account Setting" />
            <Breadcrumb separator=" " style={{marginTop:'10px',marginLeft:'10px',marginBottom:'5px'}} >
               <Breadcrumb.Item><ArrowLeftOutlined onClick={()=>{history.goBack()}} /></Breadcrumb.Item>
               <Breadcrumb.Item className="pageheadertitle">Setting</Breadcrumb.Item>
             </Breadcrumb>
            <div className="listcontiner">
                <div className="accountsetting">
                <Space hig="50px" color="#ffffff" />
                <div className="header">

                    <img src={UserImg} alt="userimage" />
                </div>
                <div className="content-continer">
                    <div className="name">
                        <h4 className="lable">{Constants.accountsetting.name}</h4>
                        <h4 className="value" >{leaves_userCredentials.displayName}</h4>
                    </div>
                    <div className="email">
                        <h4 className="lable">{Constants.accountsetting.email}</h4>
                        <h4 className="value" >{leaves_userCredentials.email}</h4>
                    </div>
                </div>
                <div className="form-header">
                    <h2>{Constants.accountsetting.formheader}</h2>
                </div>
                <div className="form-continer">
                    <Form name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item
                            //  label="Password"
                            name="Password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password placeholder='Password' />
                        </Form.Item>

                        <Form.Item
                            // label="Confirm Password"
                            name="cpassword"
                            rules={[{ required: true, message: 'Please input your confirm password!' }]}
                        >
                            <Input.Password placeholder='Confirm Password' />
                        </Form.Item>

                        <Form.Item style={{ "clear": "both", marginTop: "30px" }}>
                            <Button className="loginBtn" type="primary" htmlType="submit" block loading={loadings} >
                                <span className="btntext">{btnText}</span>
                            </Button>
                        </Form.Item>
                    </Form>

                </div>

            </div>
            </div>

        </div>
        //         </Col>
        //     </Row>
        // </div>
    )
}

export default AccountSetting;