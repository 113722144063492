import React from 'react';
import {LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom'
import './styles.css'
import {SearchOutlined} from '@ant-design/icons';

const PageHader=(props)=>{
    const history = useHistory();

    return(
        <div className="headerbar">
        <div onClick={()=>{history.goBack()}}  className="gobackicon">
        <LeftOutlined />
        </div>
        <div className="bartitel">
            <span className="pageheadertitel">  {props.titel}</span>
        </div>
        <div className="menucontiner">
      <div className="profile">
          </div>
      
       
      </div>
    </div>
    )
}

export default PageHader;